import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'

export default styled(Tab)(({ theme }) => ({
  '&.MuiTab-root': {
    backgroundColor: theme.palette.background.default,
    borderRadius: '8px 8px 0 0'
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.contrastText
  }
}))
