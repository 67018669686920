import currencyPipe from '../pipes/currency.pipe'

export const getTechData = (version: Version): TechData[] => {
  return [
    {
      label: 'Combústivel',
      value: version?.fuel || 'N/A'
    },
    {
      label: 'Carroceria',
      value: version?.bodyType || 'N/A'
    },
    {
      label: 'Assentos',
      value: version?.seats || 'N/A'
    },
    {
      label: 'Potência',
      value: version?.horsepower || 'N/A'
    },
    {
      label: 'Motorização',
      value: version?.engineSize || 'N/A'
    },
    {
      label: 'Transmissão',
      value: version?.transmission || 'N/A'
    }
  ]
}

export const normalizeAdvertisement = (advertisement: Advertisement): ResultCard => {
  return {
    id: advertisement.id,
    updatedAt: null,
    type: 'ADVERTISEMENT',
    linkPrefix: `/comprar/oferta/${advertisement.car.version.model!.manufacture.slug}/${
      advertisement.car.version.model!.slug
    }/${advertisement.car.version.slug}/`,
    localLink: `/comprar/oferta/${advertisement.car.version.model!.manufacture.slug}/${
      advertisement.car.version.model!.slug
    }/${advertisement.car.version.slug}/${advertisement.id}`,
    link: `${advertisement.link}?utm_source=comparacar&utm_medium=site`,
    isHotOffer: false,
    images: {
      origin: advertisement.photos.map(item => item.url),
      version: advertisement.car.version.photos?.map(item => item.url) || []
    },
    title: `${advertisement.car.version.model!.manufacture.name} ${advertisement.car.version.model!.name}`,
    subtitle: `${advertisement.car.version.modelYear} / ${advertisement.car.km} Km`,
    description: `${advertisement.car.version.name}`,
    location: `${advertisement.city.name}/${advertisement.city.state.name}`,
    price: currencyPipe(parseInt(advertisement.price)),
    priceNumber: parseInt(advertisement.price),
    brand: advertisement.search.source.name || null,
    hasApplePlay: advertisement.car.version.smartphoneConnectivity || null,
    hasAndroidAuto: advertisement.car.version.smartphoneConnectivity || null,
    hasArmor: advertisement.car.armored || false,
    hasAllWheelDrive: advertisement.car.version.allWheelDrive || null,
    showPreLinkOffer: advertisement.showPreLinkOffer || false,
    version: advertisement.car.version
  }
}

export const normalizeAdvertisements = (advertisements: Advertisement[]): ResultCard[] =>
  advertisements.map<ResultCard>(item => normalizeAdvertisement(item))

export const normalizeSubscription = (subscription: Subscription): ResultCard => {
  return {
    id: subscription.id,
    updatedAt: subscription?.updatedAt || null,
    type: 'SUBSCRIPTION',
    linkPrefix: `/assinar/oferta/${subscription.version.model!.manufacture.slug}/${subscription.version.model!.slug}/${
      subscription.version.slug
    }/`,
    localLink: `/assinar/oferta/${subscription.version.model!.manufacture.slug}/${subscription.version.model!.slug}/${
      subscription.version.slug
    }/${subscription.id}`,
    link: `${subscription.link}?utm_source=comparacar&utm_medium=site`,
    hidePrice: subscription.hidePrice || false,
    isHotOffer: false,
    images: {
      origin: subscription.photos?.map(item => item.url) || [],
      version: subscription.version.photos?.map(item => item.url) || []
    },
    title: `${subscription.version.model!.manufacture.name} ${subscription.version.model!.name}`,
    location: '',
    subtitle: subscription.version.name,
    description: `Plano de ${subscription.month} meses`,
    description2: `Franquia mensal ${subscription.km} Km`,
    price: subscription.hidePrice ? 'Preço sob-consulta*' : `${currencyPipe(parseInt(subscription.price))}/mês`,
    priceNumber: parseInt(subscription.price),
    brand: subscription.source?.name || null,
    isAuthorizedPartner: subscription.source?.authorizedPartner || false,
    hasApplePlay: subscription.version?.smartphoneConnectivity || null,
    hasAndroidAuto: subscription.version?.smartphoneConnectivity || null,
    hasAllWheelDrive: subscription.version.allWheelDrive || null,
    showPreLinkOffer: subscription.showPreLinkOffer,
    tags: subscription.tags || null,
    version: subscription.version,
    km: subscription.km,
    month: subscription.month
  }
}

export const normalizeSubscriptions = (subscriptions: Subscription[]): ResultCard[] =>
  subscriptions.map<ResultCard>(item => normalizeSubscription(item))
