import React from 'react'

import BaseBrand, { iBrand } from './BaseBrand'

const Administradores: React.FC<Omit<iBrand, 'black'>> = ({ width, height }) => {
  return (
    <BaseBrand width={width || '251'} height={height || '44'} viewBox="0 0 251 44" fill="none">
      <path
        d="M71.7086 16.408V27.8505H67.786V27.0564C66.8771 27.774 65.8439 28.2141 64.4183 28.2141C61.6055 28.2141 58.8884 26.0232 58.8884 21.9666C58.9841 18.2928 61.1272 15.834 64.734 15.834C66.9441 15.834 68.1687 16.0062 71.7086 16.408ZM62.6675 22.0336C62.6675 23.7844 63.7486 24.9899 65.2889 24.9899C66.1022 24.9899 66.925 24.7029 67.786 23.9853V19.106L65.4325 18.9816C63.5381 18.9816 62.6675 20.2541 62.6675 22.0336Z"
        fill="black"
      />
      <path
        d="M86.6624 27.6592L79.5443 28.1376C76.6549 28.1376 73.8708 26.1476 73.8708 21.938C73.9474 18.2833 76.014 15.834 79.8792 15.834C80.769 15.834 81.8501 15.9297 82.759 16.0541V10.7921H86.6529V27.6688L86.6624 27.6592ZM77.6213 21.938C77.6213 23.7845 78.5876 24.9899 80.415 24.9899C80.7977 24.9899 81.5726 24.9421 82.7685 24.8177V19.173C81.9266 19.0487 81.0847 18.9817 80.5298 18.9817C78.4633 18.9817 77.6213 20.2063 77.6213 21.938ZM109.212 20.3307V27.8506H105.319V21.4787C105.319 19.8906 104.553 19.3644 103.252 19.3644C102.597 19.3641 101.943 19.4282 101.3 19.5557C101.348 19.9671 101.377 20.3211 101.377 20.8282V27.8506H97.483V21.4787C97.483 19.8906 96.7177 19.3644 95.4356 19.3644C94.8807 19.3644 94.278 19.3931 93.5413 19.46V27.8506H89.6474V16.4559C91.8862 16.1211 94.4119 15.834 96.5263 15.834C97.5883 15.834 98.545 16.0254 99.2913 16.4368C101.023 16.0732 102.802 15.834 104.333 15.834C107.194 15.834 109.212 17.183 109.212 20.3307ZM111.499 12.4664C111.499 11.0982 112.561 10.1128 113.881 10.1128C114.193 10.1049 114.502 10.1604 114.791 10.2759C115.08 10.3914 115.343 10.5644 115.563 10.7846C115.783 11.0047 115.956 11.2673 116.072 11.5564C116.187 11.8456 116.243 12.1551 116.235 12.4664C116.235 13.8345 115.154 14.7721 113.881 14.7721C112.609 14.7721 111.499 13.7866 111.499 12.4664ZM115.823 27.8506H111.93V16.1976H115.823V27.8506ZM130.825 20.3307V27.8506H126.931V21.4787C126.931 20.0149 126.07 19.3644 124.96 19.3644C124.338 19.3644 123.563 19.4122 122.578 19.4887V27.8506H118.684V16.4081L126.06 15.834C128.921 15.834 130.825 17.6327 130.825 20.3307ZM133.112 12.4664C133.112 11.0982 134.164 10.1128 135.494 10.1128C136.824 10.1128 137.847 11.0982 137.847 12.4664C137.847 13.8345 136.766 14.7721 135.494 14.7721C134.221 14.7721 133.112 13.7866 133.112 12.4664ZM137.436 27.8506H133.542V16.1976H137.436V27.8506Z"
        fill="black"
      />
      <path
        d="M140.278 24.2244C142.268 24.9419 143.932 25.2576 144.918 25.2576C145.903 25.2576 146.554 25.0376 146.554 24.5401C146.554 23.7938 145.233 23.7173 143.913 23.4303C141.225 22.8754 139.847 21.9856 139.847 19.8521C139.847 17.3072 141.818 15.8625 145.042 15.8625C146.726 15.8625 148.764 16.2452 150.065 16.7045L149.319 19.5938C147.568 19.0676 146.027 18.7806 145.061 18.7806C144.171 18.7806 143.664 19.0676 143.664 19.5938C143.664 20.34 144.698 20.3592 146.257 20.7036C148.735 21.2107 150.295 22.0048 150.295 24.2818C150.295 26.9223 148.199 28.2235 145.004 28.2235C143.492 28.2235 141.521 27.9078 139.522 27.1902L140.268 24.2339L140.278 24.2244Z"
        fill="black"
      />
      <path
        d="M161.718 27.7356C160.369 28.0992 159.527 28.214 158.063 28.214C155.155 28.214 153.547 26.4631 153.547 23.2677V19.6895H151.309V16.207H153.547V12.2366H157.441V16.207H161.019V19.6895H157.441V22.885C157.441 24.0426 157.9 24.7315 159.096 24.7315C159.67 24.7315 160.302 24.6836 161.354 24.5401L161.718 27.7356ZM171.668 19.6512L167.678 19.7947V27.8504H163.784V16.628C166.358 16.1687 169.333 15.8817 171.668 15.8817V19.6608V19.6512Z"
        fill="black"
      />
      <path
        d="M185.397 16.408V27.8505H181.474V27.0564C180.565 27.774 179.532 28.2141 178.107 28.2141C175.294 28.2141 172.577 26.0232 172.577 21.9666C172.672 18.2928 174.815 15.834 178.422 15.834C180.632 15.834 181.857 16.0062 185.397 16.408ZM176.356 22.0336C176.356 23.7844 177.437 24.9899 178.977 24.9899C179.79 24.9899 180.613 24.7029 181.474 23.9853V19.106L179.121 18.9816C177.226 18.9816 176.356 20.2541 176.356 22.0336Z"
        fill="black"
      />
      <path
        d="M200.351 27.6591L193.232 28.1375C190.343 28.1375 187.559 26.1475 187.559 21.9379C187.636 18.2832 189.702 15.834 193.567 15.834C194.457 15.834 195.538 15.9296 196.447 16.054V10.792H200.341V27.6687L200.351 27.6591ZM191.309 21.9379C191.309 23.7844 192.266 24.9899 194.094 24.9899C194.476 24.9899 195.251 24.942 196.447 24.8177V19.1729C195.605 19.0486 194.763 18.9816 194.208 18.9816C192.142 18.9816 191.3 20.2062 191.3 21.9379M215.783 21.9666C215.783 25.7361 213.094 28.2619 209.172 28.2619C205.249 28.2619 202.561 25.7361 202.561 21.9666C202.561 18.1971 205.278 15.7383 209.172 15.7383C213.065 15.7383 215.783 18.2162 215.783 21.9666ZM212.061 21.9666C212.061 20.2349 210.855 19.0773 209.181 19.0773C207.507 19.0773 206.301 20.2349 206.301 21.9666C206.301 23.6983 207.507 24.9229 209.181 24.9229C210.855 24.9229 212.061 23.7461 212.061 21.9666ZM225.838 19.6513L221.848 19.7948V27.8505H217.954V16.628C220.528 16.1688 223.503 15.8818 225.838 15.8818V19.6609V19.6513ZM238.668 21.0768C238.682 21.8196 238.608 22.5615 238.448 23.2869H230.631C231.042 24.368 232.047 25.0377 233.54 25.0377C234.621 25.0377 235.778 24.8655 237.72 24.4637L238.227 27.3051C236.62 27.8314 235.003 28.214 233.473 28.214C229.119 28.214 226.718 25.5735 226.718 22.0144C226.718 18.2641 229.311 15.834 232.851 15.834C236.391 15.834 238.639 17.9292 238.668 21.0768ZM230.497 20.9046H235.3C235.223 19.6035 234.314 18.7903 232.965 18.7903C231.616 18.7903 230.755 19.5843 230.488 20.9046"
        fill="black"
      />
      <path
        d="M240.858 24.2244C242.848 24.9419 244.513 25.2576 245.498 25.2576C246.484 25.2576 247.135 25.0376 247.135 24.5401C247.135 23.7938 245.814 23.7173 244.494 23.4303C241.806 22.8754 240.428 21.9856 240.428 19.8521C240.428 17.3072 242.399 15.8625 245.623 15.8625C247.307 15.8625 249.345 16.2452 250.646 16.7045L249.899 19.5938C248.149 19.0676 246.608 18.7806 245.642 18.7806C244.752 18.7806 244.245 19.0676 244.245 19.5938C244.245 20.34 245.278 20.3592 246.838 20.7036C249.316 21.2107 250.875 22.0048 250.875 24.2818C250.875 26.9223 248.78 28.2235 245.585 28.2235C244.073 28.2235 242.102 27.9078 240.103 27.1902L240.849 24.2339L240.858 24.2244Z"
        fill="black"
      />
      <path
        d="M12.6976 19.2398C12.6976 7.06067 22.8007 1.42553 29.249 0.239182C30.5693 0 30.5789 0 29.2394 0H22.5519C10.4014 0 0.547119 9.84475 0.547119 21.9952C0.547119 34.1457 10.4014 44 22.5519 44C34.7024 44 44.5471 34.1553 44.5471 21.9952H32.3967C32.3967 27.4294 27.9766 31.8495 22.5423 31.8495C15.9696 31.8495 12.688 26.7597 12.688 19.2303M44.5471 44V34.1457C44.5471 28.7784 44.7385 28.9793 42.4423 33.83C40.3658 38.2029 36.9102 41.7727 32.6071 43.9904H44.5471V44Z"
        fill="black"
      />
      <path
        d="M16.5723 12.4375C16.0939 13.6908 16.6776 14.0161 17.8639 13.3846C19.4521 12.5427 21.1455 12.1409 22.5519 12.1409H32.4062V20.7802H44.5567V0H34.3484C29.536 0 19.94 3.58774 16.5819 12.4375"
        fill="#616161"
        fillOpacity="0.9"
      />
    </BaseBrand>
  )
}

export default Administradores
