import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'

import BrandSelector from '../../atoms/BrandSelector'

const Wrapper = styled('div')(({ theme }) => ({
  paddingX: '15px',
  width: '100%',
  maxWidth: '1226px',
  margin: 'auto',
  overflow: 'hidden',
  position: 'relative',
  '&::before, &::after': {
    background: 'linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)',
    content: '""',
    position: 'absolute',
    width: '100px',
    bottom: '0',
    top: '0',
    zIndex: theme.zIndex.mobileStepper
  },
  '&::after': {
    right: '0',
    transform: 'rotateZ(180deg)'
  },
  '&::before': {
    left: '0'
  }
}))

const scroll = (size: number) => keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-204px * ${size}))
  }
`
const List = styled('div')(({ size }: PropsWithChildren<{ size: number }>) => ({
  animation: `${scroll(size)} 30s linear infinite`,
  animationDelay: '1s',
  display: 'flex',
  width: `calc(204px * ${size * 2})`,
  '& > div': {
    width: '204px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

interface iBrandsListCarrousel {
  brands: (Partial<Provider & { href: string }> & { height: number; width: number })[]
}

const BrandsListCarrousel: React.FC<iBrandsListCarrousel> = ({ brands }) => {
  return (
    <Wrapper>
      <List size={brands.length}>
        {brands.map((brand, i) => (
          <div key={i}>
            <BrandSelector name={brand.slug} width={brand.width} height={brand.height} href={brand.href} />
          </div>
        ))}
        {brands.map((brand, i) => (
          <div key={i}>
            <BrandSelector name={brand.slug} width={brand.width} height={brand.height} href={brand.href} />
          </div>
        ))}
      </List>
    </Wrapper>
  )
}

export default BrandsListCarrousel
