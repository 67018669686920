import React from 'react'

import BaseBrand, { iBrand } from './BaseBrand'

const ComparaCar: React.FC<Omit<iBrand, 'black'>> = ({ width, height }) => {
  return (
    <BaseBrand width={width || '179'} height={height || '44'} viewBox="0 0 179 44" fill="none">
      <path
        opacity="0.995"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3819 0C28.6617 0 30.9414 0 33.2212 0C33.2212 2.34932 33.2212 4.69864 33.2212 7.04796C35.305 7.04796 37.3888 7.04796 39.4726 7.04796C39.4726 8.9879 39.4726 10.9279 39.4726 12.8679C37.3888 12.8679 35.305 12.8679 33.2212 12.8679C33.2122 16.6232 33.2212 20.3786 33.2479 24.1339C33.2657 24.5077 33.2835 24.8814 33.3013 25.2552C33.3991 26.1537 33.8889 26.6965 34.7707 26.8837C36.2668 26.9002 37.7629 26.8913 39.2589 26.857C39.3273 28.5576 39.4163 30.2572 39.5261 31.9561C39.5245 32.0855 39.5067 32.2101 39.4726 32.3298C37.7757 32.7356 36.0569 32.9936 34.3165 33.104C32.8212 33.1169 31.3697 32.8766 29.9618 32.3832C28.5483 31.8248 27.6132 30.8193 27.1567 29.3665C26.6886 27.8724 26.4393 26.3418 26.4086 24.7746C26.3819 20.8057 26.373 16.8368 26.3819 12.8679C25.3845 12.8679 24.3872 12.8679 23.3898 12.8679C23.3898 10.9279 23.3898 8.9879 23.3898 7.04796C24.3872 7.04796 25.3845 7.04796 26.3819 7.04796C26.3819 4.69864 26.3819 2.34932 26.3819 0Z"
        fill="black"
      />
      <path
        opacity="0.995"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6982 0C91.9781 0 94.2575 0 96.5374 0C96.5374 2.34932 96.5374 4.69864 96.5374 7.04796C98.6213 7.04796 100.705 7.04796 102.789 7.04796C102.789 8.9879 102.789 10.9279 102.789 12.8679C100.705 12.8679 98.6213 12.8679 96.5374 12.8679C96.515 16.9797 96.5417 21.091 96.6176 25.2018C96.6908 26.1291 97.1808 26.6898 98.087 26.8837C99.583 26.9002 101.079 26.8913 102.575 26.857C102.646 28.5397 102.734 30.2216 102.842 31.9027C102.841 32.0498 102.823 32.1922 102.789 32.3298C101.092 32.7356 99.3731 32.9936 97.6328 33.104C96.2377 33.1083 94.8752 32.9036 93.5453 32.49C92.3874 32.0978 91.5058 31.377 90.9004 30.3276C90.2854 29.0265 89.929 27.6561 89.8318 26.2163C89.7826 25.7011 89.7468 25.185 89.7249 24.6678C89.6982 20.7345 89.6891 16.8012 89.6982 12.8679C88.7007 12.8679 87.7036 12.8679 86.7061 12.8679C86.7061 10.9279 86.7061 8.9879 86.7061 7.04796C87.7036 7.04796 88.7007 7.04796 89.6982 7.04796C89.6982 4.69864 89.6982 2.34932 89.6982 0Z"
        fill="black"
      />
      <path
        opacity="0.992"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65784 6.46063C13.0826 6.59615 16.4666 7.04107 19.8098 7.79547C19.7437 9.67963 19.7081 11.5662 19.703 13.4552C17.5192 13.1583 15.3285 12.9181 13.1309 12.7344C11.8851 12.6551 10.6384 12.6373 9.39068 12.681C8.75463 12.7013 8.14904 12.8436 7.57401 13.1081C6.96494 13.5814 6.76014 14.1954 6.95955 14.9502C7.10953 15.3849 7.4034 15.6786 7.84117 15.8312C8.5759 16.077 9.32394 16.2728 10.0853 16.4185C11.4568 16.6856 12.8282 16.9525 14.1995 17.2194C15.7907 17.5412 17.2334 18.1819 18.5275 19.1416C19.4609 20.001 20.0397 21.06 20.264 22.3185C21.3466 28.9245 18.5593 32.4929 11.902 33.0239C9.33923 33.1398 6.79236 32.9796 4.26126 32.5434C2.99415 32.3311 1.72961 32.1086 0.467625 31.876C0.554098 29.9547 0.625344 28.0326 0.681351 26.1095C2.86512 26.4063 5.05584 26.6466 7.25342 26.8303C8.46348 26.91 9.67462 26.9278 10.8868 26.8837C11.5115 26.8478 12.117 26.7232 12.7034 26.5099C13.5591 26.0718 13.853 25.3866 13.5851 24.4543C13.3744 24.0323 13.0449 23.7386 12.5966 23.5733C11.8827 23.3193 11.1525 23.1235 10.4059 22.986C8.79342 22.7115 7.19048 22.3911 5.59705 22.0249C4.29642 21.6686 3.10313 21.0991 2.01714 20.3163C1.11695 19.5054 0.55592 18.4999 0.334046 17.2995C0.0607978 15.828 0.025175 14.3508 0.227183 12.8679C0.626402 10.6724 1.79299 9.02614 3.72694 7.92896C5.59096 6.9605 7.56792 6.47104 9.65784 6.46063Z"
        fill="black"
      />
      <path
        opacity="0.993"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2428 6.46061C53.5796 6.44069 54.9154 6.46739 56.2501 6.5407C60.8308 6.97158 63.1909 9.49 63.3298 14.0959C63.3309 18.1374 63.3667 22.1775 63.4367 26.2162C63.5179 27.196 64.0431 27.7477 65.0129 27.8714C64.9616 29.5981 64.8991 31.3245 64.8259 33.0506C63.3838 33.104 61.9497 32.9884 60.5246 32.7036C59.4475 32.401 58.4413 31.9472 57.5058 31.342C54.605 32.758 51.5507 33.3008 48.3423 32.9705C45.3861 32.614 43.4715 31.0211 42.5984 28.1918C42.2194 26.6654 42.0947 25.117 42.2244 23.5466C42.3524 20.7511 43.7149 18.8556 46.3119 17.8601C47.594 17.4117 48.9119 17.1269 50.2658 17.0058C52.3486 16.8369 54.4324 16.6855 56.5173 16.552C56.5264 15.9644 56.5173 15.3771 56.4906 14.79C56.513 13.2465 55.7564 12.4189 54.2197 12.3072C53.9793 12.2983 53.7388 12.2894 53.4984 12.2805C50.3197 12.4198 47.1405 12.5444 43.9609 12.6543C43.8644 11.0699 43.8109 9.48583 43.8006 7.90223C46.5729 7.13294 49.3869 6.6524 52.2428 6.46061ZM56.2501 21.304C56.3393 21.304 56.428 21.304 56.5173 21.304C56.5173 23.1016 56.5173 24.8992 56.5173 26.6968C54.9469 27.1566 53.344 27.4235 51.7084 27.4977C50.1677 27.5608 49.3039 26.8311 49.117 25.3085C48.9857 24.3864 49.1638 23.5321 49.6513 22.7457C50.1534 22.1744 50.7856 21.8452 51.5481 21.7579C53.1244 21.6171 54.6921 21.4659 56.2501 21.304Z"
        fill="black"
      />
      <path
        opacity="0.995"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.4086 6.46063C84.5154 6.46063 84.6223 6.46063 84.7292 6.46063C84.7292 8.75656 84.7292 11.0525 84.7292 13.3484C81.9945 13.8647 79.3144 14.5766 76.6877 15.4842C76.661 21.1438 76.6519 26.8035 76.661 32.4633C74.3811 32.4633 72.1017 32.4633 69.8218 32.4633C69.8218 23.9915 69.8218 15.5198 69.8218 7.04796C72.0835 7.04796 74.3458 7.04796 76.6076 7.04796C76.5899 7.92079 76.6076 8.79286 76.661 9.66425C79.0825 8.19592 81.6649 7.12805 84.4086 6.46063Z"
        fill="black"
      />
      <path
        opacity="0.985"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.481 6.51402C149.832 6.40392 152.601 8.43288 153.789 12.6009C154.438 15.2309 154.705 17.9006 154.591 20.6099C154.573 22.585 154.306 24.525 153.789 26.4298C152.67 30.2343 150.132 32.3611 146.175 32.8104C143.267 33.112 140.382 32.9607 137.519 32.3565C137.519 36.2009 137.519 40.0452 137.519 43.8895C136.504 43.8895 135.489 43.8895 134.474 43.8895C134.474 31.609 134.474 19.3285 134.474 7.04795C135.489 7.04795 136.504 7.04795 137.519 7.04795C137.502 7.67191 137.519 8.2948 137.573 8.91673C140.004 7.42448 142.64 6.62358 145.481 6.51402ZM144.359 9.34388C147.297 9.0366 149.336 10.229 150.476 12.9212C150.98 14.4104 151.265 15.941 151.331 17.5131C151.475 19.5675 151.404 21.6143 151.118 23.6534C150.917 25.1802 150.401 26.5862 149.568 27.8715C148.743 28.8672 147.701 29.5347 146.442 29.8737C145.036 30.2017 143.611 30.3084 142.168 30.1941C140.608 30.0904 139.058 29.9036 137.519 29.6334C137.502 23.6176 137.519 17.602 137.573 11.5864C139.693 10.3876 141.955 9.6401 144.359 9.34388Z"
        fill="black"
      />
      <path
        opacity="0.983"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.136 6.56744C169.456 6.58464 170.774 6.64695 172.09 6.75432C173.948 6.93303 175.8 7.15552 177.646 7.42174C177.627 8.34812 177.591 9.27359 177.54 10.1982C174.267 9.64292 170.973 9.38481 167.655 9.424C166.339 9.46469 165.146 9.85622 164.075 10.5987C163.179 11.4107 162.752 12.4251 162.792 13.6421C162.734 14.6433 163.055 15.4976 163.754 16.205C164.376 16.622 165.053 16.9246 165.785 17.1127C167.653 17.5959 169.541 17.9875 171.448 18.2873C173.03 18.583 174.544 19.0813 175.99 19.7824C177.222 20.4715 177.979 21.5126 178.261 22.9059C179.434 28.886 177.003 32.2052 170.967 32.8638C168.825 33.0377 166.688 32.9843 164.556 32.7036C163.024 32.4914 161.492 32.2867 159.961 32.0896C160.046 31.1658 160.117 30.2403 160.174 29.3131C163.023 29.7857 165.891 30.0705 168.777 30.1674C170.471 30.268 172.074 29.9387 173.586 29.1796C174.635 28.4999 175.213 27.53 175.322 26.2697C175.448 25.2395 175.324 24.2428 174.948 23.2796C174.558 22.5454 173.962 22.056 173.158 21.8113C171.973 21.4084 170.761 21.1058 169.525 20.9036C168.31 20.7131 167.099 20.4995 165.892 20.2629C164.492 19.9807 163.156 19.5179 161.884 18.8747C161.089 18.3991 160.51 17.7317 160.148 16.8724C158.501 11.0026 160.684 7.61214 166.693 6.70093C167.182 6.65933 167.663 6.61486 168.136 6.56744Z"
        fill="black"
      />
      <path
        opacity="0.985"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.598 7.04791C108.613 7.04791 109.628 7.04791 110.643 7.04791C110.634 12.1738 110.643 17.2995 110.67 22.4253C110.704 23.9596 110.9 25.4724 111.258 26.9637C111.709 28.7313 112.84 29.7547 114.651 30.0339C117.95 30.4087 121.022 29.7413 123.868 28.0316C123.894 21.037 123.903 14.0425 123.894 7.04791C124.909 7.04791 125.925 7.04791 126.94 7.04791C126.94 15.5197 126.94 23.9915 126.94 32.4633C125.925 32.4633 124.909 32.4633 123.894 32.4633C123.912 31.8571 123.894 31.252 123.841 30.6479C120.237 32.9033 116.372 33.5174 112.246 32.49C110.355 31.9345 109.117 30.7154 108.533 28.8325C108.049 27.1579 107.764 25.4493 107.678 23.7067C107.6 18.1544 107.573 12.6014 107.598 7.04791Z"
        fill="black"
      />
      <path
        opacity="0.957"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.937 35.4C169.311 35.4 169.685 35.4 170.059 35.4C170.041 36.3439 170.059 37.2872 170.113 38.2298C170.813 37.752 171.579 37.6185 172.41 37.8294C173.092 38.0791 173.475 38.5686 173.559 39.2977C173.677 40.3861 173.659 41.4718 173.505 42.5547C173.243 43.5458 172.593 44.0263 171.555 43.9963C171.005 43.975 170.516 43.797 170.086 43.4624C170.059 43.6037 170.051 43.7461 170.059 43.8895C169.685 43.8895 169.311 43.8895 168.937 43.8895C168.937 41.0597 168.937 38.2298 168.937 35.4ZM170.967 38.39C171.819 38.2228 172.309 38.5609 172.437 39.4045C172.473 40.3478 172.473 41.291 172.437 42.2343C172.334 43.1191 171.845 43.4662 170.967 43.2755C170.633 43.2239 170.34 43.0904 170.086 42.8751C170.05 41.5224 170.05 40.1698 170.086 38.8172C170.361 38.628 170.655 38.4856 170.967 38.39Z"
        fill="black"
      />
      <path
        opacity="0.951"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.64 37.6959C160.328 37.6257 160.853 37.8749 161.216 38.4434C162.033 37.789 162.95 37.5843 163.968 37.8294C164.384 37.9782 164.625 38.2719 164.689 38.7104C164.716 40.4367 164.725 42.1631 164.716 43.8895C164.378 43.8895 164.039 43.8895 163.701 43.8895C163.725 42.1792 163.698 40.4706 163.621 38.7638C163.48 38.4217 163.221 38.2615 162.846 38.2832C162.283 38.3377 161.775 38.5335 161.323 38.8705C161.296 40.5435 161.287 42.2165 161.296 43.8895C160.94 43.8895 160.584 43.8895 160.228 43.8895C160.237 42.1987 160.228 40.5079 160.201 38.8172C160.082 38.4277 159.815 38.2497 159.4 38.2832C158.845 38.3431 158.347 38.5389 157.903 38.8705C157.877 40.5435 157.868 42.2165 157.877 43.8895C157.52 43.8895 157.164 43.8895 156.808 43.8895C156.808 41.8606 156.808 39.8316 156.808 37.8027C157.164 37.8027 157.52 37.8027 157.877 37.8027C157.868 37.9815 157.877 38.1595 157.903 38.3366C158.432 37.968 159.011 37.7545 159.64 37.6959Z"
        fill="black"
      />
      <path
        opacity="0.95"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.603 37.7492C147.102 37.7404 147.6 37.7492 148.099 37.7759C148.793 37.8475 149.248 38.2124 149.461 38.8705C149.53 39.2409 149.557 39.6146 149.541 39.9918C149.185 39.9918 148.829 39.9918 148.473 39.9918C148.637 38.6143 148.049 38.1071 146.71 38.47C146.446 38.614 146.294 38.8365 146.255 39.1375C146.149 40.2765 146.149 41.4156 146.255 42.5547C146.317 42.9202 146.522 43.1605 146.87 43.2755C147.388 43.4343 147.859 43.3542 148.286 43.0352C148.369 42.904 148.423 42.7616 148.446 42.608C148.473 42.2703 148.482 41.9322 148.473 41.5936C148.829 41.5936 149.185 41.5936 149.541 41.5936C149.727 43.214 149.006 44.0149 147.377 43.9963C146.799 44.0008 146.256 43.8673 145.748 43.5958C145.302 43.2109 145.079 42.7214 145.08 42.1275C145.032 41.1469 145.051 40.1681 145.133 39.1909C145.301 38.3739 145.791 37.8933 146.603 37.7492Z"
        fill="black"
      />
      <path
        opacity="0.96"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.373 37.7493C154.723 37.4636 155.721 38.4958 155.366 40.8461C155.664 43.3871 154.559 44.3927 152.053 43.8628C151.253 43.5911 150.834 43.0304 150.797 42.1809C150.761 41.291 150.761 40.4012 150.797 39.5113C150.866 38.5081 151.392 37.9208 152.373 37.7493ZM152.854 38.3366C153.758 38.2525 154.212 38.6618 154.217 39.5647C154.253 40.419 154.253 41.2732 154.217 42.1275C154.245 42.9267 153.862 43.3361 153.068 43.3556C152.222 43.3196 151.839 42.8746 151.919 42.0208C151.874 41.0582 151.892 40.0971 151.973 39.1375C152.077 38.6548 152.371 38.3878 152.854 38.3366Z"
        fill="black"
      />
      <path
        opacity="0.931"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.86 37.7493C178.038 37.7493 178.216 37.7493 178.394 37.7493C178.403 38.0523 178.394 38.3549 178.368 38.657C177.558 38.4403 176.863 38.6361 176.284 39.2443C176.257 40.7926 176.248 42.341 176.257 43.8895C175.866 43.8895 175.473 43.8895 175.082 43.8895C175.082 41.8606 175.082 39.8316 175.082 37.8027C175.473 37.8027 175.866 37.8027 176.257 37.8027C176.248 38.0525 176.257 38.3016 176.284 38.5502C176.723 38.1039 177.248 37.8369 177.86 37.7493Z"
        fill="black"
      />
      <path
        opacity="0.884"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.595 42.6614C143.005 42.6614 143.415 42.6614 143.824 42.6614C143.824 43.0708 143.824 43.4801 143.824 43.8895C143.415 43.8895 143.005 43.8895 142.595 43.8895C142.595 43.4801 142.595 43.0708 142.595 42.6614Z"
        fill="black"
      />
      <path
        opacity="0.914"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.319 42.6614C166.711 42.6614 167.103 42.6614 167.494 42.6614C167.494 43.0708 167.494 43.4801 167.494 43.8895C167.103 43.8895 166.711 43.8895 166.319 43.8895C166.319 43.4801 166.319 43.0708 166.319 42.6614Z"
        fill="black"
      />
    </BaseBrand>
  )
}

export default ComparaCar
